/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import { Box, useMediaQuery } from '@mui/system'
import CalendarWithEvents from '../../components/calendar/CalendarWithEvents'
import HomePageSliderMobile from '../../components/slider/HomePageSliderMobile'
import { PageSubTitle } from '../../components/title/PageSubTitle'
import HomePageChartMobile from '../../components/charts/HomePageChartMobile'
import InformationTopRight from '../../components/tooltip/InformationTopRight'
import CompetenceContainerMobile from './competence/CompetenceContainerMobile'
import { Typography } from '@mui/material'
import SelectInput from '../../components/input/SelectInput'
import { DatePickerField } from '../../components/input/DatePickerInput'

// Styles
import { useTheme } from '@mui/material/styles'
import styles from './styles/homeMobile.module.scss'

// Utils
import { format, formatDistance } from 'date-fns'

const HomeMobileContainer = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [endDate, setEndDate] = useState<Date | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [page, setPage] = useState(1)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))


  const options = [
    { label: 'ENEM', value: 'ENEM' },
    { label: 'Concurso', value: 'concurso' },
    { label: 'ITA', value: 'ita' }

  ]

  const bancaOptions = [
    { label: 'Selecione...', value: 'placeholder' },
    ...options
  ]

  const resetLocation = () => {
    const newLocation = {
      ...location,
      state: null
    }
    navigate(newLocation)
  }

  const handleStartChanges = (startValue: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const startDateFormatted = startValue ? format(startValue, 'yyyy-MM-dd') : ''
    if (endDate && formatDistance(startValue, endDate)) {
      setEndDate(null)

    } else {
      return null
    }
    setPage(1)
    setStartDate(startValue)
    resetLocation()
  }

  const handleEndChanges = (endValue: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const endDateFormatted = endValue ? format(endValue, 'yyyy-MM-dd') : ''
    setEndDate(endValue)
    setPage(1)
    resetLocation()
  }
  return (
    <Box className={styles.container}>
      <div className={styles.calendar_container}>
        <CalendarWithEvents />
      </div>
      <div className={styles.slider_container}>
        <PageSubTitle>Produções textuais</PageSubTitle>
        <HomePageSliderMobile />
      </div>
      <div className={styles.chart_container}>
        <PageSubTitle>Histórico</PageSubTitle>
        <Box
          sx={{
            display: 'flex',
            gap: lgDown ? '24px' : '48px',
            paddingTop: lgDown ? '24px' : 0,
            marginBottom: '19px',
            flexDirection: lgDown ? 'column' : 'row'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography>Banca:</Typography>
            <SelectInput
              width='fit-content'
              name='selectSubject'
              border='none'
              onChange={() => null}
              defaultValue='placeholder'
              options={bancaOptions}
            />
          </Box>
          <Box
            sx={{
              display: 'flex'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px'
              }}
            >
              <Box>
                <Typography
                  color={theme.palette.text.primary}
                  variant='subtitle2'
                  component='p'
                >
                  De
                </Typography>
              </Box>
              <Box>
                <DatePickerField
                  name='start_date'
                  onChange={handleStartChanges}
                  startDate={startDate}
                  endDate={endDate}
                  placeholder='dd/mm/aaaa'
                  selectsStart
                />
              </Box>
            </Box>
            <Box

              sx={{
                display: 'flex ',
                alignItems: 'center',
                gap: '8px',
                marginLeft: '8px'
              }}
            >
              <Box>
                <Typography
                  color={theme.palette.text.primary}
                  variant='subtitle2'
                  component='p'
                >
                  até
                </Typography>
              </Box>
              <Box>
                <DatePickerField
                  name='end_date'
                  onChange={handleEndChanges}
                  startDate={endDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholder='dd/mm/aaaa'
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          className={styles.chart}
          sx={{
            position: 'relative',
            '& .apexcharts-legend': {
              display: 'none'
            },
            '& .apexcharts-toolbar': {
              zIndex: 1
            },
            '& tspan': {
              fontSize: '10px',
              fontWeight: 500,
              color: theme.palette.text.primary
            }
          }}
        >
          <InformationTopRight info='Visualize sua evolução! Acompanhe suas notas por atividade, banca e filtre por datas específicas.' />
          <HomePageChartMobile />
          <div className={styles.labels_container}>
            <p className={styles.label_low}>
              <span className={styles.circle_low} />  0 a 400
            </p>
            <p className={styles.label_mid}>
              <span className={styles.circle_mid} />  400 a 800
            </p>
            <p className={styles.label_high}>
              <span className={styles.circle_high} />  800 a 1000
            </p>
          </div>
        </Box>
      </div>
      <div className={styles.competences_container}>
        <CompetenceContainerMobile />
      </div>
    </Box>
  )
}

export default HomeMobileContainer