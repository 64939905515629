import httpV2 from '../../utils/httpV2'

// Endpoints
const getStudentActivityListEndpoint = '/student/activity/list/'
const getStudentActivityEndpoint = '/student/activity'


export const getStudentActivityListService = (params: any) => {
  return httpV2.get(getStudentActivityListEndpoint, { params })
}

export const getStudentActivityByIdService = (params: { id: string }) => {
  return httpV2.get(`${getStudentActivityEndpoint}/${params.id}/`)
}