import React from 'react'

// Assets
import EssaySentImage from '../../assets/activities/essay-sent-image.png'

// Components
import DialogModal from './DialogModal'
import { Box, styled, Typography } from '@mui/material'
import { PageSubTitle } from '../title/PageSubTitle'

const Image = styled('img')(() => ({
  marginBottom: '40px'
}))

type IEssaySubmittedConfirmationModalProps = {
  hasCloseButton: boolean
  open: boolean
  handleClose: () => void
  closeOnBackdropClick: boolean
}

const EssaySubmittedConfirmationModal: React.FC<IEssaySubmittedConfirmationModalProps> = ({
  hasCloseButton,
  open,
  handleClose,
  closeOnBackdropClick
}) => {
  return (
    <DialogModal
      hasCloseButton={hasCloseButton}
      open={open}
      handleClose={handleClose}
      closeOnBackdropClick={closeOnBackdropClick}
      width='566px'
      height='546px'
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          padding: '33px 70px 56px 70px'
        }}
      >
        <Image src={EssaySentImage} />
        <PageSubTitle mb='12px'>Redação enviada</PageSubTitle>
        <Typography
          sx={{
            textAlign: 'center',
            maxWidth: '422px',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '150%'
          }}
        >
          Aguarde a correção do seu texto pela equipe de corretores especializados.
        </Typography>
      </Box>
    </DialogModal>
  )
}

export default EssaySubmittedConfirmationModal