import React, { useState } from 'react'

import SendMessageIcon from '../../assets/chat/send-message-icon.svg'
import styles from './styles/chatMessageInput.module.scss'

const MessageInput = () => {
  const [message, setMessage] = useState('')

  const handleSend = () => {
    if (message.trim()) {
      setMessage('')
    }
  }

  return (
    <div className={styles.message_input_container}>
      <input
        type='text'
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder='Escreva sua mensagem'
        className={styles.message_input}
      />
      <button className={styles.send_button} onClick={handleSend}>
        <img src={SendMessageIcon} />
      </button>
    </div>
  )
}

export default MessageInput
