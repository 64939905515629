import { createReducer } from '@reduxjs/toolkit'
import { StateType } from './types'
import * as actions from './actions'

const initialState: StateType = {
  items: [],
  item: {
    id: null,
    name: '',
    subject: '',
    sheet_name: '',
    jury: '',
    max_grade: 0,
    start_datetime: '',
    end_datetime: '',
    rewrite_previous_id: null,
    rewrite_next_id: null,
    file_pdf: '',
    file_image: '',
    answer: {
      status: '',
      grade: null,
      file_image: ''
    }
  },
  isFetching: false,
  isError: false,
  errorMessage: ''
}

const activityReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getStudentActivityListRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      errorMessage: ''
    }))
    .addCase(actions.getStudentActivityListSuccess, (state: StateType, action: { payload: any }): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      errorMessage: '',
      items: action.payload.activities,
    }))
    .addCase(actions.getStudentActivityListRejected, (state: StateType, action: { payload: any }): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    }))
    .addCase(actions.getStudentActivityByIdRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      errorMessage: ''
    }))
    .addCase(actions.getStudentActivityByIdSuccess, (state: StateType, action: { payload: any }): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      errorMessage: '',
      item: action.payload,
    }))
    .addCase(actions.getStudentActivityByIdRejected, (state: StateType, action: { payload: any }): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    }))
})

export default activityReducer
