import React, { useState } from "react";
import { Box } from '@mui/material';
import Chart from "react-apexcharts";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import { useTheme } from '@mui/material/styles'
import './styles/home-chart.modules.scss'

const HomePageChartMobile = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const theme = useTheme()
  const [page, setPage] = useState(0)
  const itemsPerPage = 6

  const seriesData = [800, 1000, 400, 900, 800, 700, 200, 800, 300, 1000, 500];
  const categories = ['Atv1', 'Atv2', 'Atv3', 'Atv4', 'Atv5', 'Atv6', 'Atv7', 'Atv8', 'Atv9', 'Atv10', 'Atv11'];

  const startIndex = page * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const paginatedSeriesData = seriesData.slice(startIndex, endIndex);
  const paginatedCategories = categories.slice(startIndex, endIndex);

  const getColors = (data: any) => {
    return data.map((value: any) => {
      if (value <= 400) {
        return '#E6D8FF';
      } else if (value <= 799) {
        return '#8842FE';
      } else {
        return '#30037C';
      }
    });
  };

  const options = {
    chart: {
      id: "basic-bar"
    },
    xaxis: {
      categories: paginatedCategories
    },
    plotOptions: {
      bar: {
        distributed: true,
        borderRadius: 4,
        columnWidth: '8px',
      }
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      show: false
    },
    colors: getColors(paginatedSeriesData)
  };

  const series = [
    {
      name: "Nota",
      data: paginatedSeriesData
    }
  ];

  const handleNext = () => {
    if (endIndex < seriesData.length) {
      setPage(page + 1);
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setPage(page - 1);
    }
  };

  return (
    <Box
      sx={{
        position: 'relative'
      }}
      className='app'>
      <Box sx={{ paddingInline: '20px'}} className='row'>
        <div className='mixed-chart'>
          <Chart
            options={options}
            series={series}
            type='bar'
            width='100%'
            height='250px'
          />
        </div>
      </Box>

      <button
        type='button'
        onClick={handlePrev}
        className='prev-chart-btn'
        disabled={startIndex === 0}
      >
        <span
          style={{
            position: 'absolute',
            borderWidth: 0
          }}
        >
        </span>
        <ArrowBackIosNewRoundedIcon sx={{ fontSize: '15px' }} />
      </button>
      <button
        onClick={handleNext}
        type='button'
        className='next-chart-btn'
        disabled={endIndex >= seriesData.length}
      >
        <span style={{
          position: 'absolute'
        }}
        >
        </span>
        <ArrowForwardIosRoundedIcon sx={{ fontSize: '15px' }} />
      </button>
    </Box>
  );
}

export default HomePageChartMobile;
