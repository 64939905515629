import React, { useEffect, useState } from 'react'

// Assets
import ClockIcon from '../../../assets/activities/clock-icon.svg'
import TrashIcon from '../../../assets/activities/trash-icon.svg'

// Components
import InformationIconWithTooltip from '../../tooltip/InformationIconWithTooltip'
import { Box, Switch, Typography } from '@mui/material'
import ImageUpload from '../ImageUpload'
import { RoundedButton } from '../../button/RoundedButton'
import { Field, Form } from 'react-final-form'
import EssaySubmittedConfirmationModal from '../../modal/EssaySubmittedConfirmationModal'
import CounterModal from '../../modal/CounterModal'

// Style
import styles from './essayUpload.module.scss'
import { styled, useTheme } from '@mui/material/styles'

// Utils
import { formatTime } from '../../../utils/functions'

// eslint-disable-next-line @typescript-eslint/ban-types
type IEssayUploadProps = {}


const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-disabled': {
      '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.grey[400]
      },
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.grey[500],
      },
    },
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        ...theme.applyStyles('dark', {
          backgroundColor: '#BEBEBE',
        }),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.text.primary,
  },
  '&:hover': {
    cursor: 'not-allowed',
    '& .MuiSwitch-switchBase.Mui-disabled': {
      cursor: 'not-allowed',
    }
  }
}))

const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: '20px',
  fontWeight: 500,
  lineHeight: '120%',
  width: '100%',
  whiteSpace: 'nowrap'
}))

// eslint-disable-next-line no-empty-pattern
const EssayUpload: React.FC<IEssayUploadProps> = ({

}) => {
  const theme = useTheme()

  const [checked, setChecked] = useState(false)
  const [open, setOpen] = React.useState(false)
  const [openConfirmation, setOpenConfirmation] = React.useState(false)
  const [count, setCount] = useState(0)
  const [isCounting, setIsCounting] = useState(false)
  const [timeSent, setTimeSent] = useState(false)
  const [save, setSave] = useState(false)

  useEffect(() => {
    let timer: any
    if (isCounting) {
      timer = setInterval(() => {
        setCount((prevTime) => prevTime + 10)
      }, 10)
    }

    return () => clearInterval(timer)
  }, [isCounting])

  const startCounting = () => {
    setIsCounting(true)
  }

  const sendTime = () => {
    setIsCounting(false)
    setOpen(false)
    setTimeSent(true)
  }

  const handleClose = () => {
    setOpen(false)
    setChecked(false)
    setCount(0)
    setTimeSent(false)
  }
  const handleCloseConfirmation = () => {
    setOpenConfirmation(false)
  }

  const handleDeleteFile = (form: any) => {
    form.change('uploadedImage', null)
    setSave(false)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked
    setChecked(isChecked)
    if (isChecked) {
      setOpen(true)
      startCounting()
    } else {
      setChecked(false)
      setCount(0)
      setTimeSent(false)
    }
  }

  let submit: any
  const handleCustomSubmit = (event: any) => {
    setOpenConfirmation(true)
    setSave(true)
    submit(event)
  }

  const onSubmit = (values: any) => {
    // eslint-disable-next-line no-console
    console.log('Form Submitted:', values)
  }

  return (
    <Form
      onSubmit={onSubmit}
      render={
        ({ handleSubmit, values, form }) => {
          submit = handleSubmit
          return (
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  marginTop: '28px'
                }}
              >

                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: '20px',
                    marginBottom: '12px',
                    justifyContent: 'space-between'
                  }}
                >
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: '8px'
                    }}
                  >
                    <img src={ClockIcon} />
                    <StyledText>Adicionar Escrita Rápida</StyledText>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: '8px',
                        width: '100%'
                      }}
                    >
                      <MaterialUISwitch
                        checked={checked}
                        disabled={values?.uploadedImage}
                        onChange={handleChange}
                        color='primary'
                        sx={{
                          '.MuiSwitch-thumb': {
                            backgroundColor: checked ? theme.palette.primary.light : theme.palette.text.primary
                          }
                        }}
                      />
                      <InformationIconWithTooltip
                        info='Grava tempo que levou para escrever a redação até enviar o arquivo.'
                      />
                      {
                        timeSent &&
                        <div className={styles.time_spent}>
                          <img src={ClockIcon} />
                          <Typography>{formatTime(count)}</Typography>
                        </div>
                      }
                    </Box>
                  </Box>
                  {
                    values?.uploadedImage && <Box display='flex' gap='8px'>
                      <RoundedButton
                        sx={{
                          background: theme.palette.error.main
                        }}
                        onClick={() => handleDeleteFile(form)}
                      >
                        <img src={TrashIcon} />
                        Deletar
                      </RoundedButton>
                      <RoundedButton
                        disabled={save}
                        onClick={event => handleCustomSubmit(event)}
                        type='submit'
                      >
                        {save ? 'Enviado' : 'Enviar'}
                      </RoundedButton>
                    </Box>
                  }
                </Box>
                <Field name='uploadedImage'>
                  {({ input }) => (
                    <ImageUpload
                      isEssay
                      imageData={values?.uploadedImage}
                      setImage={(file: any) => {
                        input.onChange(file)
                      }}
                      type='file'
                      maxWidth='100%'
                      {...input}
                    />
                  )}
                </Field>
                <Field
                  name='timeSpent'
                  render={({ input }) => {
                    useEffect(() => {
                      input.onChange(count)
                    }, [count])
                    return <input type='hidden' {...input} />;
                  }}
                />
                <CounterModal
                  hasCloseButton={false}
                  open={open}
                  handleClose={handleClose}
                  closeOnBackdropClick={false}
                  count={count}
                  sendTime={sendTime}
                />
                <EssaySubmittedConfirmationModal
                  hasCloseButton={true}
                  open={openConfirmation}
                  handleClose={handleCloseConfirmation}
                  closeOnBackdropClick={true}
                />
              </Box>
            </form>
          )
        }
      }
    />
  )
}

export default EssayUpload