import React, { useEffect } from "react"

// Components
import HomeMobileContainer from '../containers/home/HomeMobileContainer'
import HomeDesktopContainer from '../containers/home/HomeDesktopContainer'

// Styles
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/system'

// Redux
import { useDispatch } from 'react-redux'
import { getHomeStatisticsRequest } from '../store/ homeStatistics/actions'

const Home = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    document.title = "Home | Verbo"
    dispatch(getHomeStatisticsRequest())
  }, [])

  
  return (

    smDown ? <HomeMobileContainer /> : <HomeDesktopContainer />
  )
}

export default Home