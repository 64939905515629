import React from 'react'

// Assets
import GradesImage from '../../assets/grades/grades-image.png'
import RightBottomCornerShape from '../../assets/home/card/right-bottom-corner-shape.svg'
import RightTopCornerShape from '../../assets/home/card/right-top-corner.svg'

// Components
import {
  Box,
  CircularProgress,
  Tooltip,
  Typography
} from '@mui/material'

// Styles
import {
  useTheme,
  styled
} from '@mui/material/styles'
import styles from './styles/textAvarage.module.scss'

type ITextAverageScoreCardProps = {
  average: number
  isFetching: boolean
}

const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  background: '#F2F5FF',
  borderRadius: '32px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  position: 'relative',
  maxWidth: '272px',
  width: '100%',

  [theme.breakpoints.down(1240)]: {
    flexDirection: 'row',
    gap: '60px',
    padding: '8px 70px',
    maxWidth: '100%'
  }
}))

const Image = styled('img')(() => ({

}))

const SeeAllNavigate = styled('a')(({ theme }) => ({
  background: theme.palette.primary.light,
  borderRadius: '56px',
  color: '#fff',
  cursor: 'pointer',
  display: 'block',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '120%',
  marginTop: '14px',
  padding: '15px 30px',
  textDecoration: 'none',
  width: 'fit-content',
  whiteSpace: 'nowrap',
  [theme.breakpoints.up(905)]: {
    marginBottom: '26px'
  }
}))

const TextAverageScoreCard: React.FC<ITextAverageScoreCardProps> = ({
  average,
  isFetching
}) => {
  const theme = useTheme()
  return (
    <Container>
      <Box sx={{
        alignItems: 'center',
        background: '#E6D8FF',
        borderTopLeftRadius: '32px',
        borderTopRightRadius: '32px',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '8px',
        width: '100%',
      }}
      >
        <Image src={GradesImage} />
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {
          isFetching ? (
            <CircularProgress />
          ) : (
            <>
              <Typography
                sx={{
                  color: 'var(--palette-text-tertiary)',
                  fontSize: '56px',
                  fontWeight: 700,
                  lineHeight: '120%',
                  textAlign: 'center'
                }}
              >
                {average}
              </Typography>
              <Typography
                sx={{
                  color: 'var(--palette-text-tertiary)',
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '150%',
                  textAlign: 'center'
                }}
              >
                Nota média
              </Typography>
            </>
          )

        }
        <SeeAllNavigate href='/notas'>Ver todas</SeeAllNavigate>
      </Box>
      <Box
        sx={{
          alignItems: 'flex-end',
          backgroundImage: `url(${RightBottomCornerShape})`,
          bottom: 0,
          display: 'flex',
          height: '48px',
          justifyContent: 'flex-end',
          position: 'absolute',
          right: 0,
          width: '50px',
          [theme.breakpoints.down(1240)]: {
            alignItems: 'flex-start',
            backgroundImage: `url(${RightTopCornerShape})`,
            display: 'flex',
            height: '45px',
            justifyContent: 'flex-end',
            position: 'absolute',
            right: 0,
            top: 0,
            width: '45px',
          }
        }}
      >
        <Tooltip
          title='Consulte o histórico completo com as notas das suas redações'
        >
          <span className={styles.icon} />
        </Tooltip>
      </Box>
    </Container>
  )
}

export default TextAverageScoreCard