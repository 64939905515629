import { Box } from '@mui/material'
import React from 'react'
import SearchInput from './SearchInput'
import SearchIcon from '../../assets/home/search-icon.svg'

type ICustomSearchInputProps = {
  name: string
  value: string
  onChange: any
}

const CustomSearchInput: React.FC<ICustomSearchInputProps> = ({
  name,
  value,
  onChange
}) => {
  return (
    <Box width='100%'>
      <SearchInput
        name={name}
        value={value}
        onChange={onChange}
        placeholder='Faça sua pesquisa'
        borderRadius='56px'
        icon={SearchIcon}
      />
    </Box>
  )
}

export default CustomSearchInput