import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

// Assets
import DownloadIcon from '../../../assets/activities/download-icon.svg'

// Components
import CustomSearchInput from '../../../components/input/CustomSearchInput'
import { Box, Tab } from '@mui/material'
import Notification from '../../../components/notification/Notification'
import LogoutButton from '../../../components/button/logout/LogoutButton'
import PersonalDataDropDownMenu from '../../../components/dropDown/PersonalDataDropDownMenu'
import NavigationTitleWithBackButton from '../../../components/navigation/NavigationTitleWithBackButton'
import ActivityTitleWithData from '../../../components/title/ActivityTitleWithData'
import ActivityTitleWithScore from '../../../components/title/ActivityTitleWithScore'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { RoundedButtonColorLight } from '../../../components/button/RoundedButtonColorLight'
import EssayUpload from '../../../components/imageUpload/activity/EssayUpload'

// Redux
import { IActivity } from '../../../store/activity/types'

// Style
import styles from './style/pedingEssayDesktop.module.scss'
import { useTheme } from '@mui/material/styles'
import { format } from 'date-fns'

const ActivityPendingEssayDesktopContainer = () => {
  const theme = useTheme()
  const location = useLocation()
  const state: IActivity = location?.state?.data

  const essayTheme = state?.name
  const classroom = 'API return nothing...'
  const startDate = state?.end_datetime && format(state?.start_datetime, 'dd/MM/yyyy')
  const endDate = state?.end_datetime && format(state?.end_datetime, 'dd/MM/yyyy')
  const essayTitle = state?.name
  const essayScore = Number(state?.answer?.grade)
  const essayInstruction = state?.file_image

  const [searchName, setSearchName] = useState<string>('')
  const [tabValue, setTabValue] = useState('1')

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchName(value)
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }

  return (
    <div className={styles.container}>
      <div
        className={styles.top_header}
      >
        <CustomSearchInput
          name='search'
          value={searchName}
          onChange={handleSearch}
        />

        <div
          className={styles.notification_container}
        >
          <Box display='flex' gap='8px'>
            <Notification
              background='#F2F5FF'
            />
            <LogoutButton
              background='#F2F5FF'
            />
          </Box>
          <PersonalDataDropDownMenu
            background='#F2F5FF'
          />
        </div>
      </div>
      <NavigationTitleWithBackButton
        backTo='/atividades'
        text='Atividades / Minhas atividades'
      />
      <ActivityTitleWithScore
        title={essayTitle}
        score={essayScore}
        maxWidth='934px'
      />
      <ActivityTitleWithData
        theme={essayTheme}
        classroom={classroom}
        essayStartDate={startDate}
        essayEndDate={endDate}

      />

      <Box>
        <TabContext value={tabValue}>
          <Box className={styles.tabs_container}
            sx={{ borderBottom: '2px solid rgba(224, 224, 224, 0.40)', borderColor: 'divider' }}
          >
            <TabList
              onChange={handleTabChange}
              sx={{
                '.Mui-selected': {
                  color: theme.palette.text.primary + '!important',
                  fontWeight: 600,
                  fontSize: '24px'
                },
                '.MuiTab-root': {
                  color: theme.palette.text.secondary,
                  fontWeight: 600,
                  fontSize: '24px'
                },
                '.MuiTabs-indicator': {
                  background: theme.palette.primary.light,
                }
              }}
            >
              <Tab label='Atividade' value='1' />
              <Tab label='Envio' value='2' />
            </TabList>
            {
              tabValue === '1' &&
              <RoundedButtonColorLight sx={{ paddingInline: '25px' }}>
                <img src={DownloadIcon} />
                Baixar atividade
              </RoundedButtonColorLight>
            }
          </Box>
          <TabPanel value='1'>
            <Box>
              <img
                width='80%'
                src={essayInstruction}
                alt='Instruções para realização da redação'
              />
            </Box>
          </TabPanel>
          <TabPanel value='2'>
            <EssayUpload />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  )
}

export default ActivityPendingEssayDesktopContainer