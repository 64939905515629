import React from 'react'

// Assets
import ClockImage from '../../assets/activities/clock-image.png'

// Components
import DialogModal from './DialogModal'
import { Box } from '@mui/material'
import { RoundedButton } from '../button/RoundedButton'

// Styles
import styles from './styles/counterModal.module.scss'

// Utils
import { formatTime } from '../../utils/functions'

type ICounterModalProps = {
  hasCloseButton: boolean
  open: boolean
  handleClose: () => void
  closeOnBackdropClick: boolean
  count: number
  sendTime: () => void
}

const CounterModal: React.FC<ICounterModalProps> = ({
  hasCloseButton,
  open,
  handleClose,
  closeOnBackdropClick,
  count,
  sendTime
}) => {
  return (
    <DialogModal
      hasCloseButton={hasCloseButton}
      open={open}
      handleClose={handleClose}
      closeOnBackdropClick={closeOnBackdropClick}
      width='556px'
      height='546px'
    >
      <Box className={styles.modal_container}>
        <img className={styles.clock_image} src={ClockImage} />
        <p className={styles.modal_text}>
          Agora é com você
        </p>
        <p className={styles.counter}>
          {formatTime(count)}
        </p>
        <Box className={styles.buttons_container}>
          <RoundedButton
            sx={{
              background: '#3F3E3E',
              padding: '15px 56px'
            }}
            onClick={handleClose}
            type='button'
          >
            Cancelar
          </RoundedButton>
          <RoundedButton sx={{ padding: '15px 56px' }} onClick={sendTime} >Enviar</RoundedButton>
        </Box>
      </Box>
    </DialogModal>
  )
}

export default CounterModal