import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

// Assets
import ActivitiesImage from '../../assets/activities/activities-mobile-image.png'

// Components
import { Box, Typography } from '@mui/material';
import PageCardWithImageAndTooltipMobile from '../../components/card/PageCardWithImageAndTooltipMobile';
import { PageSubTitle } from '../../components/title/PageSubTitle';
import ActivityCardMobile from '../../components/card/ActivityCardMobile';
import SelectInput from '../../components/input/SelectInput';
import { DatePickerField } from '../../components/input/DatePickerInput';

// Redux
import { IActivity } from '../../store/activity/types';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store/hooks';

// Styles
import { useTheme } from '@mui/material/styles'
import styles from './styles/activitiesMobile.module.scss'

// Utils
import { format, formatDistance } from 'date-fns';
import { getStudentActivityListRequest } from '../../store/activity/actions';


const ActivitiesMobileContainer = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const { items } = useAppSelector(state => state.activity)

  const status = [
    { label: 'Todas', value: 'all' },
    { label: 'Concluída', value: 'concluded' },
    { label: 'Em Correção', value: 'in_correction' },
    { label: 'Pendente', value: 'pending' },
    { label: 'Prazo Expirado', value: 'pending' },
  ]

  const statusOptions = [
    { label: 'Selecione...', value: 'placeholder' },
    ...status
  ]
  const options = [
    { label: 'ENEM', value: 'ENEM' },
    { label: 'Concurso', value: 'concurso' },
    { label: 'ITA', value: 'ita' }
  ]

  const bancaOptions = [
    { label: 'Selecione...', value: 'placeholder' },
    ...options
  ]

  const resetLocation = () => {
    const newLocation = {
      ...location,
      state: null
    }
    navigate(newLocation)
  }

  const handleGetActivitiesItems = (params: object) => {
    dispatch(getStudentActivityListRequest(params))
  }

  const handleStartChanges = (startValue: any) => {
    const startDateFormatted = startValue ? format(startValue, 'yyyy-MM-dd') : ''
    if (endDate && formatDistance(startValue, endDate)) {
      setEndDate(null)
      handleGetActivitiesItems({
        end_datetime_after: startDateFormatted,
        page: 1
      })
    } else {
      handleGetActivitiesItems({
        end_datetime_after: startDateFormatted,
        page: 1
      })
    }
    setPage(1)
    setStartDate(startValue)
    resetLocation()
  }

  const handleEndChanges = (endValue: any) => {
    const endDateFormatted = endValue ? format(endValue, 'yyyy-MM-dd') : ''
    setEndDate(endValue)
    setPage(1)
    handleGetActivitiesItems({
      end_datetime_before: endDateFormatted,
      page: 1
    })
    resetLocation()
  }

  return (
    <div className={styles.container}>
      <PageCardWithImageAndTooltipMobile
        photo={ActivitiesImage}
        info='Aqui, você gerencia suas redações enviadas, acompanha o progresso, visualiza as notas e recebe orientações para melhorar sua escrita.'
      />
      <div className={styles.title_container}>
      </div>
      <PageSubTitle>Minhas atividades</PageSubTitle>
      <div className={styles.filters}>
        <Box className={styles.filter_container}>
          <label>Status</label>
          <SelectInput
            width='fit-content'
            name='selectSubject'
            border='none'
            onChange={() => null}
            defaultValue='placeholder'
            options={statusOptions}
          />
        </Box>
        <Box className={styles.filter_container}>
          <label>Banca</label>
          <SelectInput
            width='fit-content'
            name='selectSubject'
            border='none'
            onChange={() => null}
            defaultValue='placeholder'
            options={bancaOptions}
          />
        </Box>
        <Box
          sx={{
            display: 'flex'
          }}
        >
          <Box
            className={styles.filter_container}
          >
            <Box>
              <Typography
                color={theme.palette.text.primary}
                variant='subtitle2'
                component='p'
              >
                Iniciam entre
              </Typography>
            </Box>
            <Box display='flex' gap='8px'>
              <DatePickerField
                name='start_date'
                onChange={handleStartChanges}
                startDate={startDate}
                endDate={endDate}
                placeholder='dd/mm/aaaa'
                selectsStart
              />
              <DatePickerField
                name='end_date'
                onChange={handleEndChanges}
                startDate={endDate}
                endDate={endDate}
                minDate={startDate}
                placeholder='dd/mm/aaaa'
              />
            </Box>
          </Box>
        </Box>
      </div>
      <div className={styles.cards_container}>
        {
          items.map(
            (item: IActivity) => {
              return (
                <ActivityCardMobile
                  key={item.id}
                  name={item.name}
                  banca={item.jury}
                  endDate={item.end_datetime}
                  rewritten={false}
                  status={item.answer.status}
                  score={item.answer.grade}
                  item={item}
                />
              )
            }
          )
        }
      </div>
    </div>
  )
}

export default ActivitiesMobileContainer