import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import HomePageChart from './HomePageChart'
import { Box, Typography, useMediaQuery } from '@mui/material'
import InformationTopRight from '../tooltip/InformationTopRight'
import SelectInput from '../input/SelectInput'
import { DatePickerField } from '../input/DatePickerInput'
import { PageSubTitle } from '../title/PageSubTitle'

// Styles
import { useTheme } from '@mui/material/styles'
import styles from '../../components/charts/styles/chart.module.scss'

// Utils
import { format, formatDistance } from 'date-fns'

const ChartContainer = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [endDate, setEndDate] = useState<Date | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [page, setPage] = useState(1)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))


  const options = [
    { label: 'ENEM', value: 'ENEM' },
    { label: 'Concurso', value: 'concurso' },
    { label: 'ITA', value: 'ita' }

  ]

  const bancaOptions = [
    { label: 'Selecione...', value: 'placeholder' },
    ...options
  ]

  const resetLocation = () => {
    const newLocation = {
      ...location,
      state: null
    }
    navigate(newLocation)
  }

  const handleStartChanges = (startValue: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const startDateFormatted = startValue ? format(startValue, 'yyyy-MM-dd') : ''
    if (endDate && formatDistance(startValue, endDate)) {
      setEndDate(null)

    } else {
      return null
    }
    setPage(1)
    setStartDate(startValue)
    resetLocation()
  }

  const handleEndChanges = (endValue: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const endDateFormatted = endValue ? format(endValue, 'yyyy-MM-dd') : ''
    setEndDate(endValue)
    setPage(1)
    resetLocation()
  }


  return (
    <div className={styles.chart_container}>
      <div className={styles.filters_container}>
        <PageSubTitle>Histórico</PageSubTitle>
        <Box
          sx={{
            display: 'flex',
            gap: lgDown ? '24px' : '48px',
            paddingTop: lgDown ? '24px' : 0,
            marginBottom: '19px',
            flexDirection: lgDown ? 'column' : 'row'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography>Banca:</Typography>
            <SelectInput
              width='fit-content'
              name='selectSubject'
              border='none'
              onChange={() => null}
              defaultValue='placeholder'
              options={bancaOptions}
            />
          </Box>
          <Box
            sx={{
              display: 'flex'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px'
              }}
            >
              <Box>
                <Typography
                  color={theme.palette.text.primary}
                  variant='subtitle2'
                  component='p'
                >
                  De
                </Typography>
              </Box>
              <Box>
                <DatePickerField
                  name='start_date'
                  onChange={handleStartChanges}
                  startDate={startDate}
                  endDate={endDate}
                  placeholder='dd/mm/aaaa'
                  selectsStart
                />
              </Box>
            </Box>
            <Box

              sx={{
                display: 'flex ',
                alignItems: 'center',
                gap: '8px',
                marginLeft: '8px'
              }}
            >
              <Box>
                <Typography
                  color={theme.palette.text.primary}
                  variant='subtitle2'
                  component='p'
                >
                  até
                </Typography>
              </Box>
              <Box>
                <DatePickerField
                  name='end_date'
                  onChange={handleEndChanges}
                  startDate={endDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholder='dd/mm/aaaa'
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
      <Box
        className={styles.chart}
        sx={{
          position: 'relative',
          '& .apexcharts-legend': {
            display: 'none'
          },
          '& .apexcharts-toolbar': {
            zIndex: 1
          },
          '& tspan': {
            fontSize: '10px',
            fontWeight: 500,
            color: theme.palette.text.primary
          }
        }}
      >
        <InformationTopRight info='Visualize sua evolução! Acompanhe suas notas por atividade, banca e filtre por datas específicas.' />
        <HomePageChart />
        <div className={styles.labels_container}>
          <p className={styles.label_low}>
            <span className={styles.circle_low} />  0 a 400
          </p>
          <p className={styles.label_mid}>
            <span className={styles.circle_mid} />  400 a 800
          </p>
          <p className={styles.label_high}>
            <span className={styles.circle_high} />  800 a 1000
          </p>
        </div>
      </Box>
    </div>
  )
}

export default ChartContainer
