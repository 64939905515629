import React from 'react'

// Assets
import GradesImage from '../../assets/grades/grades-image.png'
import RightBottomCornerShape from '../../assets/home/card/right-bottom-corner-shape.svg'
import RightTopCornerShape from '../../assets/home/card/right-top-corner.svg'

// Components
import {
  Box,
  Tooltip,
  Typography
} from '@mui/material'

// Styles
import {
  useTheme,
  styled
} from '@mui/material/styles'
import styles from './styles/textAvarage.module.scss'

type ITextAverageScoreCardMobileProps = {
  average: number
}

const Container = styled(Box)(() => ({
  alignItems: 'center',
  background: '#F2F5FF',
  borderRadius: '32px',
  display: 'flex',
  flexDirection: 'column',
  height: '210px',
  position: 'relative',
  width: '161px',
}))

const Image = styled('img')(() => ({

}))

const TextAverageScoreCardMobile: React.FC<ITextAverageScoreCardMobileProps> = ({
  average
}) => {
  const theme = useTheme()
  return (
    <Container>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        background: '#E6D8FF',
        width: '100%',
        borderTopLeftRadius: '32px',
        borderTopRightRadius: '32px',
        marginBottom: '8px'
      }}
      >
        <Image src={GradesImage} />
      </Box>
      <Box>
        <Typography
          sx={{
            color: 'var(--palette-text-tertiary)',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '150%',
            textAlign: 'center'
          }}
        >
          Nota média
        </Typography>
        <Typography
          sx={{
            color: 'var(--palette-text-tertiary)',
            fontSize: '48px',
            fontWeight: 700,
            lineHeight: '120%',
            textAlign: 'center'
          }}
        >
          {average}
        </Typography>
      </Box>
      <Box
        sx={{
          alignItems: 'flex-end',
          backgroundImage: `url(${RightBottomCornerShape})`,
          bottom: 0,
          display: 'flex',
          height: '48px',
          justifyContent: 'flex-end',
          position: 'absolute',
          right: 0,
          width: '50px',
          [theme.breakpoints.down(1240)]: {
            backgroundImage: `url(${RightTopCornerShape})`,
            top: 0,
            position: 'absolute',
            right: 0,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            width: '45px',
            height: '45px'
          }
        }}
      >
        <Tooltip
          title='Consulte o histórico completo com as notas das suas redações'
        >
          <span className={styles.icon} />
        </Tooltip>
      </Box>
    </Container>
  )
}

export default TextAverageScoreCardMobile