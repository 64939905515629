import { all, call, takeLatest, put } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'

import * as services from './services'
import * as actions from './actions'
import { AnyAction } from 'redux'

export function* getStudentActivityListSaga({ payload }: AnyAction) {
  try {
    const response: AxiosResponse<any> = yield call(services.getStudentActivityListService, payload)
    if (response.status === 200) {
      yield put(actions.getStudentActivityListSuccess(response?.data))
    }
  } catch (error) {
    yield put(actions.getStudentActivityListRejected(error))
  }
}
export function* getStudentActivityByIdSaga({ payload }: AnyAction) {
  try {
    const response: AxiosResponse<any> = yield call(services.getStudentActivityByIdService, payload)
    if (response.status === 200) {
      yield put(actions.getStudentActivityByIdSuccess(response?.data))
    }
  } catch (error) {
    yield put(actions.getStudentActivityByIdRejected(error))
  }
}

// Watchers
export function* watchGetStudentActivityListData() {
  yield takeLatest(actions.getStudentActivityListRequest.type, getStudentActivityListSaga)
}
export function* watchGetStudentActivityByIdData() {
  yield takeLatest(actions.getStudentActivityByIdRequest.type, getStudentActivityByIdSaga)
}

export default function* activitySagas() {
  yield all([
    watchGetStudentActivityListData(),
    watchGetStudentActivityByIdData()
  ])
}
