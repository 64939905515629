import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '../../utils/functions'

export interface getStudentActivityItemsPayload {
  per_page?: number | null
  page?: number | null
  end_datetime_after?: string | null
  end_datetime_before?: string | null
  sheet_name?: string | null
  jury?: string | null
}
export interface getStudentActivityByIdPayload {
  id: string
}

export const getStudentActivityListRequest = createAction('users:GET_STUDENT_ACTIVITY_LIST_REQUEST', withPayloadType<getStudentActivityItemsPayload>())
export const getStudentActivityListSuccess = createAction('users:GET_STUDENT_ACTIVITY_LIST_SUCCESS', withPayloadType<any>())
export const getStudentActivityListRejected = createAction('users:GET_STUDENT_ACTIVITY_LIST_REJECTED', withPayloadType<any>())

export const getStudentActivityByIdRequest = createAction('users:GET_STUDENT_ACTIVITY_BY_ID_REQUEST', withPayloadType<getStudentActivityByIdPayload>())
export const getStudentActivityByIdSuccess = createAction('users:GET_STUDENT_ACTIVITY_BY_ID_SUCCESS', withPayloadType<any>())
export const getStudentActivityByIdRejected = createAction('users:GET_STUDENT_ACTIVITY_BY_ID_REJECTED', withPayloadType<any>())