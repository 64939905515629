/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Assets
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'

// Components
import {
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
} from '@mui/material'
import InformationIconWithTooltip from '../tooltip/InformationIconWithTooltip'

// Utils
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isEqual,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  parseISO,
  startOfToday,
} from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { truncateText } from '../../utils/functions'

// Redux
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { IActivity } from '../../store/activity/types'
import { getStudentActivityListRequest } from '../../store/activity/actions'

// Styles
import './calendar-styles.scss'
import { styled, useTheme } from '@mui/material/styles'

export default function CalendarWithEvents() {

  const StatusColor = styled('span')(({ color }) => ({
    alignItems: 'center',
    background: color,
    borderRadius: '50%',
    display: 'flex',
    height: '7px',
    width: '7px',
  }))

  const StatusContainer = styled('div')(() => ({
    alignItems: 'center',
    display: 'flex',
    gap: '4px'
  }))

  const theme = useTheme()
  const today = startOfToday()
  const formattedToday = format(today, 'EEE')
  const [selectedDay, setSelectedDay] = useState(today)
  const [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'))
  const firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date())
  const translatedMonth = format(firstDayCurrentMonth, 'MMMM', { locale: ptBR })
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getStudentActivityListRequest({ per_page: 999 }))
  }, [])


  const { items, isFetching } = useAppSelector(state => state.activity)

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
  }

  const days = eachDayOfInterval({
    start: firstDayCurrentMonth,
    end: endOfMonth(firstDayCurrentMonth),
  })

  function previousMonth() {
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 })
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
  }

  function nextMonth() {
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
  }

  const colStartClasses = [
    '',
    'col-start-2',
    'col-start-3',
    'col-start-4',
    'col-start-5',
    'col-start-6',
    'col-start-7',
  ]

  const firstDay = days[0].getDate().toString().padStart(2, '0')
  const startDate = `${format(firstDayCurrentMonth, 'yyyy-MM')}-${firstDay}`


  // const itemsData = Array.isArray(items) && items.map(item => item.activities)

  const activitiesData = items ? ([] as any[]).concat(...items) : []

  // const activitiesDataNoArchived = activitiesData && activitiesData.filter(activity => {
  //   return activity.status !== 'archived'
  // })

  const eventsByDay: any = {}

  activitiesData?.forEach((activity: IActivity) => {
    const eventDay = format(parseISO(activity.start_datetime), 'yyyy-MM-dd')
    if (!eventsByDay[eventDay] || (eventsByDay[eventDay] && !eventsByDay[eventDay]?.[activity.answer.status])) {
      eventsByDay[eventDay] = { ...eventsByDay[eventDay], [activity.answer.status]: 1 }
    } else {
      eventsByDay[eventDay] = { ...eventsByDay[eventDay], [activity.answer.status]: eventsByDay[eventDay]?.[activity.answer.status] + 1 }
    }
  })

  const selectedDayMeetings = activitiesData && activitiesData.filter((activity: any) =>
    isSameDay(parseISO(activity.start_datetime), selectedDay)
  )

  const verifyStatus = (status: string) => {

    let bgColor: string;
    if (status === 'corrected') {
      bgColor = '#F4FBE6'
    } else if (status === 'in_correction') {
      bgColor = 'yellow'
    } else if (status === 'not_corrected') {
      bgColor = 'red'
    } else if (status === 'visualized') {
      bgColor = 'blue'
    } else if (status === 'overdue_enabled') {
      bgColor = 'purple'
    } else if (status === 'not_visualized') {
      bgColor = 'green'
    } else {
      bgColor = 'transparent'
    }
    return bgColor
  }

  const ActivitiesByDay = ( { activity }: { activity: IActivity }) => {
    const startDateTime = format(new Date(activity.start_datetime), 'dd/MM')
    const endDateTime: any = format(new Date(activity.end_datetime), 'dd/MM')
    const status = activity.answer.status
    const today = format(new Date(), 'dd/MM')
    const endDate: any = format(new Date(activity.end_datetime), 'dd/MM')
    const deadLineDate = String(today) === String(endDate)

    let color
    let fontColor
    let dateColor = '#fff'
    if (status === 'closed') {
      color = theme.palette.secondary.main
      fontColor = '#fff'
      dateColor = '#fff'
    } else if (status === 'waiting') {
      color = '#F4FBE6'
      fontColor = theme.palette.text.primary
      dateColor = theme.palette.text.primary
    } else if (status === 'opened' && deadLineDate) {
      color = theme.palette.error.main
      fontColor = theme.palette.error.main
      dateColor = '#fff'
    } else if (status === 'opened') {
      color = theme.palette.primary.main
      fontColor = theme.palette.text.primary
      dateColor = '#fff'
    } else {
      fontColor = theme.palette.text.primary
      color = ''
      dateColor = theme.palette.text.primary
    }

    return (
      <Table aria-label='custom table'>
        <TableBody>
          <TableRow className='tableRow'>
            <TableCell
              onClick={() => navigate(`/atividade/${activity?.id}/visualizar`)}
              sx={{
                borderBottom: '1px solid #BEBEBE',
                cursor: 'pointer',
                color: fontColor,
                fontSize: '14px',
                fontWeight: 500,
                paddingBottom: '16px',
                paddingTop: 0,
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              {truncateText(activity?.name, 53)}
            </TableCell>
            <TableCell
              sx={{
                borderBottom: '1px solid #BEBEBE',
                fontSize: '14px',
                paddingBottom: '16px',
                paddingTop: 0
              }}
              align='right'
            >
              <Typography
                sx={{
                  background: color,
                  color: dateColor,
                  textAlign: 'center',
                  padding: '8px',
                  borderRadius: '40px',
                  fontWeight: 500
                }}
              >
                {startDateTime}
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: '1px solid #BEBEBE',
                fontSize: '14px',
                paddingRight: 0,
                paddingBottom: '16px',
                paddingTop: 0
              }}
              align='right'
            >
              <Typography
                sx={{
                  background: color,
                  color: dateColor,
                  textAlign: 'center',
                  padding: '8px',
                  borderRadius: '40px',
                  fontWeight: 500
                }}
              >
                {endDateTime}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const weekDays = [
    {
      br: 'Dom',
      us: 'Sun'
    },
    {
      br: 'Seg',
      us: 'Mon'
    },
    {
      br: 'Ter',
      us: 'Tue'
    },
    {
      br: 'Qua',
      us: 'Wed'
    },
    {
      br: 'Qui',
      us: 'Thu'
    },
    {
      br: 'Sex',
      us: 'Fri'
    },
    {
      br: 'Sab',
      us: 'Sat'
    },
  ]

  return (
    <div
      style={{
        paddingTop: '16px',
        width: '100%',
        marginInline: 'auto'
      }}
    >
      <div className='container'>
        <div style={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <h2 className='month'>
            {translatedMonth}
          </h2>
          <Box
            sx={{
              display: 'flex',
              gap: '18px'
            }}
          >
            <button
              type='button'
              onClick={previousMonth}
              className='prev-month-btn'
            >
              <span
                style={{
                  position: 'absolute',
                  width: '1px',
                  height: '1px',
                  padding: 0,
                  margin: '-1px',
                  overflow: 'hidden',
                  clip: 'rect(0, 0, 0, 0)',
                  whiteSpace: 'nowrap',
                  borderWidth: 0
                }}
              >
              </span>
              <ArrowBackIosNewRoundedIcon sx={{ fontSize: '20px' }} />
            </button>
            <button
              onClick={nextMonth}
              type='button'
              className='next-month-btn'
            >
              <span style={{
                position: 'absolute',
                width: '1px',
                height: '1px',
                padding: 0,
                margin: '-1px',
                overflow: 'hidden',
                clip: 'rect(0, 0, 0, 0)',
                whiteSpace: 'nowrap',
                borderWidth: 0
              }}
              >
              </span>
              <ArrowForwardIosRoundedIcon sx={{ fontSize: '20px' }} />
            </button>
          </Box>
        </div>
        {
          isFetching && (
            <Box mt={'12px'}>
              <LinearProgress
                sx={{ height: '3px' }}
                color='primary'
                variant='indeterminate'
              />
            </Box>
          )
        }
        <Box className='weekDaysContainer'>
          {
            weekDays?.map((day, index) => {
              return (
                <Box key={index} sx={{
                  color: formattedToday === day.us ? theme.palette.primary.light + '!important' : theme.palette.text.primary
                }}>{day.br}</Box>
              )
            })
          }

        </Box>
        <div className='monthDaysContainer'>
          {days.map((day, dayIdx) => (
            <div
              key={day.toString()}
              style={{
                paddingTop: '0.375rem',
                paddingBottom: '0.375rem'
              }}
              className={classNames(
                dayIdx === 0 && colStartClasses[getDay(day)]
              )}
            >
              <button
                type='button'
                onClick={() => setSelectedDay(day)}
                style={{
                  flexDirection: 'column'
                }}
                className={'daysBtn' + classNames(
                  isEqual(day, selectedDay) && 'text-white',
                  !isEqual(day, selectedDay) && isToday(day) && 'text-red',
                  !isEqual(day, selectedDay) && !isToday(day) && isSameMonth(day, firstDayCurrentMonth) && 'text-gray900',
                  !isEqual(day, selectedDay) && !isToday(day) && !isSameMonth(day, firstDayCurrentMonth) && 'text-gray400',
                  isEqual(day, selectedDay) && isToday(day) && 'bg-isToday',
                  !isEqual(day, selectedDay) && isToday(day) && 'bg-isToday',
                  isEqual(day, selectedDay) && !isToday(day) && 'bg-gray900',
                  !isEqual(day, selectedDay) && 'bg-hover',
                  (isEqual(day, selectedDay) && !isToday(day)) && 'font-semibold',
                  'styles'
                )}
              >
                <time
                  className='dayDate'
                  style={{
                    color: (
                      isEqual(day, selectedDay) &&
                      isToday(day) ||
                      !isEqual(day, selectedDay) &&
                      isToday(day)
                    ) ? '#fff'
                      : theme.palette.text.primary
                  }}
                  dateTime={format(day, 'yyyy-MM-dd')}>
                  {format(day, 'd')}
                </time>
                <div style={{ display: 'flex', gap: '2px' }}>
                  {eventsByDay[format(day, 'yyyy-MM-dd')] && (
                    Array.from(Array(Object.keys(eventsByDay[format(day, 'yyyy-MM-dd')])), (e) => {
                      return e.map((status: string, i: number) => {
                        return (
                          <div
                            key={i}
                            style={{
                              width: '6px',
                              height: '6px',
                              borderRadius: '50%',
                              backgroundColor: verifyStatus(status)
                            }}
                          />
                        )
                      })
                    })
                  )}
                </div>
              </button>
            </div>
          ))}
        </div>
        <section className='daysEvent'>
          <div className='calendar_label_container'>
            <div className='label_container'>
              <div className='started_activity' />
              Atividade iniciada
            </div>
            <div className='label_container'>
              <div className='dead_line_date' />
              Data final de entrega
            </div>
          </div>
          <div className='daysEvent_container'>
            <h2>
              Atividades em aberto
            </h2>
            <InformationIconWithTooltip info='Acompanhe suas atividades com prazos e status' />
          </div>
          {(selectedDayMeetings.length > 0) && <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: '10px', border: 'none', textAlign: 'left' }}>Titulo</TableCell>
                <TableCell sx={{ fontSize: '10px', textAlign: 'right', border: 'none' }} align='right'>Inicio</TableCell>
                <TableCell sx={{ fontSize: '10px', textAlign: 'right', border: 'none', maxWidth: '40px' }} align='right'>Término</TableCell>
              </TableRow>
            </TableHead>
          </Table>}
          <ol className='activityList'>
            {selectedDayMeetings.length > 0 ? (
              selectedDayMeetings.map((activity: IActivity) => {
                return (
                  <ActivitiesByDay activity={activity} key={activity.id} />
                )
              })
            ) : (
              <p className='noActivity'>Sem atividades no dia.</p>
            )}
          </ol>
        </section>
      </div>
    </div>
  )
}
