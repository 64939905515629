
import React from 'react'
import { RouteProps } from 'react-router-dom'

// Components
import DashboardTemplate from '../components/template/DashboardTemplate'

// Pages
import NonLoggedPage from '../pages/NonLoggedPage'
import LoginPage from '../pages/LoginPage'
import Home from '../pages/HomePage'
import GuidedWritingPage from '../pages/GuidedWritingPage'
import SuccessPanelPage from '../pages/SuccessPanelPage'
import ActivitiesPage from '../pages/ActivitiesPage'
import GradesPage from '../pages/GradesPage'
import AddNewThemePage from '../pages/AddNewThemePage'
import ActivityVisualizeEssayPage from '../pages/ActivityVisualizeEssayPage'
import ActivityPendingEssayPage from '../pages/ActivityPendingEssayPage'

export interface Route extends Omit<RouteProps, "component"> {
  path: string,
  component: React.ElementType,
  template?: React.ElementType,
  default?:boolean,
  onlyNoAuth?: boolean,
  key?: string,
  exact?: boolean
}

export const privateRoutes: Route[] = [
  {
    path: '/',
    component: Home,
    default: true,
    template: DashboardTemplate
  },
  {
    path: '/redacao-guiada',
    component: GuidedWritingPage,  
    template: DashboardTemplate
  },
  {
    path: '/painel-sucesso',
    component: SuccessPanelPage,
    template: DashboardTemplate
  },
  {
    path: '/atividades',
    component: ActivitiesPage,
    template: DashboardTemplate
  },
  {
    path: '/notas',
    component: GradesPage,
    template: DashboardTemplate
  },
  {
    path: '/redacao-guiada/adicionar-tema',
    component: AddNewThemePage,
    template: DashboardTemplate
  },
  {
    path: '/minhas-atividades/visualizar/conluida/:id',
    component: ActivityVisualizeEssayPage,
    template: DashboardTemplate
  },
  {
    path: '/minhas-atividades/visualizar/pendente/:id',
    component: ActivityPendingEssayPage,
    template: DashboardTemplate
  },
  {
    path: '/minhas-atividades/visualizar/prazo-expirado/:id',
    component: ActivityVisualizeEssayPage,
    template: DashboardTemplate
  },
  {
    path: '/minhas-atividades/visualizar/em-correcao/:id',
    component: ActivityVisualizeEssayPage,
    template: DashboardTemplate
  }
]

export const notLoggedRoutes: Route[] = [
  {
    path: '/',
    component: NonLoggedPage,
    onlyNoAuth: true
  },
  {
    path: '/login',
    component: LoginPage,
    onlyNoAuth: true
  }
]