import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

// Assets
import DownloadIcon from '../../../assets/activities/download-icon.svg'
import ReportIcon from '../../../assets/activities/report-icon.svg'

// Component
import CustomSearchInput from '../../../components/input/CustomSearchInput'
import Notification from '../../../components/notification/Notification'
import { Box } from '@mui/material'
import LogoutButton from '../../../components/button/logout/LogoutButton'
import PersonalDataDropDownMenu from '../../../components/dropDown/PersonalDataDropDownMenu'
import { PageSubTitle } from '../../../components/title/PageSubTitle'
import { RoundedButton } from '../../../components/button/RoundedButton'
import EssayCard from '../../../components/card/EssayCard'
import EssayCompetenceCollapse from '../../../components/collapse/activity/EssayCompetenceCollapse'
import NavigationTitleWithBackButton from '../../../components/navigation/NavigationTitleWithBackButton'
import ActivityTitleWithData from '../../../components/title/ActivityTitleWithData'
import ChatContainer from '../../../components/chat/ChatContainer'

// Styles
import styles from './style/desktop.module.scss'
import { useTheme, styled } from '@mui/material/styles'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ScoreChip = styled('span')(({ theme }) => ({
  borderRadius: '40px',
  color: '#fff',
  fontWeight: 600,
  fontSize: '24px',
  padding: '8px 16px',
}))

const ActivityConcludedEssayDesktopContainer = () => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const theme = useTheme()
  const location = useLocation()
  const state = location.state
  const [searchName, setSearchName] = useState<string>('')

  const timeSpentOnFirstSubmission = state?.data?.essay?.firstSentTime
  const timeSpentOnSecondSubmission = state?.data?.essay?.secondSentTime
  const essayImageFirstSubmission = state?.data?.essay?.firstSent
  const essayImageSecondSubmission = state?.data?.essay?.secondSent
  const essaySecondSubmission = state?.data?.essay?.secondSent
  const essaySecondSubmissionDate = state?.data?.essay?.firstSentDate


  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchName(value)
  }

  const getBackgroundColor = (score: number) => {
    let background
    if (score >= 900) {
      background = '#7EEB89'
    } else if (score >= 700 && score < 900) {
      background = theme.palette.text.primary
    } else if (score >= 400 && score < 700) {
      background = '#F6BB8C'
    } else if (score < 400) {
      background = theme.palette.error.main
    }
    return background
  }

  return (
    <div
      className={styles.container}
    >
     <ChatContainer />
      <Box
        sx={{
          position: 'absolute',
          right: 0,
          bottom: '140vh',
          zIndex: 999
        }}
      >
      </Box>

      <div
        className={styles.top_header}
      >
        <CustomSearchInput
          name='search'
          value={searchName}
          onChange={handleSearch}
        />
        <div
          className={styles.notification_container}
        >
          <Box display='flex' gap='8px'>
            <Notification
              background='#F2F5FF'
            />
            <LogoutButton
              background='#F2F5FF'
            />
          </Box>
          <PersonalDataDropDownMenu
            background='#F2F5FF'
          />
        </div>
      </div>
      <NavigationTitleWithBackButton
        backTo='/atividades'
        text='Atividades / Minhas atividades'
      />
      <div
        className={styles.activity_title_container}
      >
        <h1 className={styles.title}>{state.data.titulo}</h1>
        <div className={styles.button_container}>
          <div className={styles.score_container}>
            {
              state.data.nota && (
                <>
                  <span className={styles.label}>Nota:</span>
                  <ScoreChip sx={{ background: getBackgroundColor(state.data.nota) }}>{state.data.nota}</ScoreChip>
                </>
              )
            }
          </div>
          <RoundedButton>
            <img src={DownloadIcon} />
            Baixar atividade
          </RoundedButton>
        </div>
      </div>
      <ActivityTitleWithData
        theme={state?.data?.essay?.theme}
        classroom={state?.data?.classroom}
        essayStartDate={state?.data?.startDate}
        essayEndDate={state?.data?.endDate}
        essayFirstSubmissionDate={state?.data?.essay?.firstSentDate}
        essaySecondSubmissionDate={essaySecondSubmissionDate}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: !essaySecondSubmission ? 'row' : 'column',
          gap: '24px'
        }}
      >
        <Box className={styles.essay_container}>
          <EssayCard
            essay={essayImageFirstSubmission}
            timeSpent={timeSpentOnFirstSubmission}
          />
          {
            essaySecondSubmission
            && <EssayCard
              essay={essayImageSecondSubmission}
              timeSpent={timeSpentOnSecondSubmission}
              isSecondSubmission
            />
          }
        </Box>
        <Box className={styles.report_container}>
          <PageSubTitle>
            <img src={ReportIcon} />
            Relatório de correção
          </PageSubTitle>
          <h3 className={styles.reports_accordion_title}>Notas por critério</h3>
          <EssayCompetenceCollapse
            data={state?.data?.correction?.competences}
          />
        </Box>
      </Box>
    </div >
  )
}

export default ActivityConcludedEssayDesktopContainer