import React from 'react'

// Assets
import CloseIcon from '../../assets/addNewTheme/close-icon.svg'

// Components

import { Box } from '@mui/material'

// Style
import styles from './styles/chatBubble.module.scss'

// Utils
import { useTheme, styled } from '@mui/material/styles'
import ChatBallon from './ChatBallon'
import MessageInput from './ChatMessageInput'

const Image = styled('img')(() => ({


}))
const Button = styled('button')(() => ({
  position: 'absolute',
  top: '24px',
  right: '16px',
  cursor: 'pointer'
}))

type IChatBubbleProps = {
  handleClose: () => void
}

const ChatBubble: React.FC<IChatBubbleProps> = ({
  handleClose
}) => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const theme = useTheme()

  return (
    <div className={styles.container}>
      <Button onClick={handleClose}>
        <Image src={CloseIcon} />
      </Button>
      <Box className={styles.chat_balloons_container}>
        <ChatBallon receiving date='20 de Abr de 2024' read />
        <ChatBallon isSending date='1 dia atrás' read />
        <ChatBallon isSending date='13 de Set de 2024' />
      </Box>
      <MessageInput />
    </div>
  )
}

export default ChatBubble