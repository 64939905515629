
export function withPayloadType<T>() {
  return (t: T) => ({ payload: t })
}

export function encodeQueryData(data: { [key: string]: any }) {
  const ret = []
  for (const d in data) {
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
  }
  return ret.join('&')
}

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

export const formatDate = (dateString: string) => {
  const months = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]
  const [day, month, year] = dateString.split("/").map(Number)
  const date = new Date(year, month - 1, day)
  const formattedDay = date.getDate()
  const formattedMonth = months[date.getMonth()]
  const formattedYear = date.getFullYear()

  return `${formattedDay} de ${formattedMonth} de ${formattedYear}`
}

export function truncateText(text: any, maxLength: any) {
  if (text.length <= maxLength) {
    return text
  } else {
    return text.substring(0, maxLength) + '...'
  }
}

export function truncateTextNoDots(text: any, maxLength: any) {
  if (text.length <= maxLength) {
    return text
  } else {
    return text.substring(0, maxLength)
  }
}

function stringToColor(string: string) {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}

export function stringAvatar(name: string, width = 36, height = 36, fontSize = 12) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: width,
      height: height,
      fontSize: fontSize
    },
    children: name.split(' ').length > 1
      ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
      : `${name[0]}${name[1].toUpperCase()}`
  }
}

export function bytesToMB(bytes: number): string {
  const megabytes = bytes / (1024 * 1024)
  return megabytes.toFixed(2)
}

export const formatTime = (time: any) => {
  const minutes = Math.floor(time / 60000)
  const seconds = Math.floor((time % 60000) / 1000)
  const milliseconds = Math.floor((time % 1000) / 10)

  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(seconds).padStart(2, '0')
  const formattedMilliseconds = String(milliseconds).padStart(2, '0')

  return `${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds}`
}

export const translateStatus = (status: string) => {

  let statusTranslated
  switch (status) {
    case 'corrected':
      statusTranslated = 'Corrigida'
      return statusTranslated
    case 'in_correction':
      statusTranslated = 'Em correção'
      return statusTranslated
    case 'not_corrected':
      statusTranslated = 'Não corrigida'
      return statusTranslated
    case 'visualized':
      statusTranslated = 'Visualizada'
      return statusTranslated
    case 'overdue_enabled':
      statusTranslated = 'Atraso'
      return statusTranslated
    case 'not_visualized':
      statusTranslated = 'Não visualizada'
      return statusTranslated
    default:
      statusTranslated = '-'
      return statusTranslated
  }
}

export const getActivityId = (path: string): string | null => {
  const num = path.match(/\d+$/);
  return num ? num[0] : null
}

export const getBackgroundColor = (order: number) => {
  const colors = {
    competenceBackground: '',
    percentageBackground: '',
    competenceSecondSentBackground: ''
  }
  if (order === 1) {
    colors.competenceBackground = '#FFF6EE'
    colors.percentageBackground = '#F6BB8C'
    colors.competenceSecondSentBackground = '#FFEAD8'
  } else if (order === 2) {
    colors.competenceBackground = '#F2ECFC'
    colors.percentageBackground = '#8842FE'
    colors.competenceSecondSentBackground = '#E6D8FF'
  } else if (order === 3) {
    colors.competenceBackground = '#DAF0F5'
    colors.percentageBackground = '#90EAFF'
    colors.competenceSecondSentBackground = '#BCE7F1'
  } else if (order === 4) {
    colors.competenceBackground = '#FFFAEC'
    colors.percentageBackground = '#FFD057'
    colors.competenceSecondSentBackground = '#F9ECC9'
  } else if (order === 5) {
    colors.competenceBackground = '#F4FBE6'
    colors.percentageBackground = '#C9FF57'
    colors.competenceSecondSentBackground = '#E3F4C0'
  }
  return colors
}